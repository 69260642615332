<template>
  <div class="assets">
    <template>
      <search-bar
        :key="$route.name"
        v-model="query"
        class="mb-6"
        placeholder="Type asset name"
        single-line
        full-width
        hide-details
      />
    </template>
  </div>
</template>

<script>
// Components
import SearchBar from '@/components/SearchBar.vue';

export default {
  name: 'Assets',

  components: { SearchBar },

  props: {
    isArchive: { type: Boolean, required: true },
    sendRequest: { type: Boolean, required: true },
  },

  data() {
    return {
      query: '',
    };
  },
};
</script>

<style lang="scss">
.assets {
  height: 100%;
}
</style>
